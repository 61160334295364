<script lang="ts" setup>
import { useField } from 'vee-validate'
import { AsfChevronProps, AsfSelectProps } from '@ui/types'

const props = withDefaults(defineProps<AsfSelectProps>(), { showLabel: true })

const fieldID = computed(() => props.name || props.id || `select-${props.id || props.name}`)
const rules = computed(() => {
  if (props.required) {
    return {
      required: props.required,
      ...props.validationRules
    }
  }

  return {
    ...props.validationRules
  }
})
const {
  errorMessage,
  value: reactiveValue,
  setValue,
  resetField
} = useField(() => fieldID.value, rules.value, {
  initialValue: props.value
})
const fieldKey = computed(() => props.name)

watch(
  () => props.value,
  (newValue) => {
    if (typeof newValue !== 'undefined' && newValue !== reactiveValue.value) {
      setValue(newValue)
    }
  }
)

const emit = defineEmits(['update:value'])

watch(reactiveValue, (newValue) => {
  emit('update:value', newValue)
})

const active = ref(props.persistent)
const handleActiveState = (value: boolean) => (active.value = value)
const chevronAppearance = computed<AsfChevronProps['appearance']>(() => (active.value ? 'top' : 'bottom'))
const hasMultipleOptions = computed(() => props.options.length > 1)

defineExpose({
  fieldKey,
  resetField
})
</script>

<template>
  <div
    v-click-outside="() => (persistent ? '' : handleActiveState(false))"
    class="asf-select"
    :class="{
      'is-disabled': disabled,
      'is-required': required,
      'is-invalid': errorMessage
    }"
  >
    <label v-if="hasMultipleOptions" class="asf-select__label" :class="{ 'sr-only': !showLabel }" :for="fieldID">
      <slot name="content-label">{{ label }}</slot>
      <slot v-if="showIcon" name="content-icon">
        <AsfChevron class="asf-select__chevron" :class="{ 'is-show-icon': showIcon }" :appearance="chevronAppearance" />
      </slot>
    </label>
    <div class="asf-select__inner" @click="handleActiveState(!active)">
      <div class="asf-select__container">
        <slot v-if="hasMultipleOptions" name="content-icon">
          <AsfChevron class="asf-select__chevron" :appearance="chevronAppearance" />
        </slot>
        <template v-if="hasMultipleOptions">
          <select
            :id="fieldID"
            v-model="reactiveValue"
            v-e2e="`ta-select-dropdown-${fieldID}`"
            :disabled="disabled"
            :name="fieldID"
            class="asf-select__field"
            :autocomplete="autocomplete"
            :aria-errormessage="errorMessage"
            @blur="() => handleActiveState(false)"
          >
            <option v-if="placeholder" value>
              {{ placeholder }}
            </option>
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              :disabled="option.disabled"
              :selected="value === option.value || option.selected"
            >
              {{ option.label || option.value }}
            </option>
          </select>
        </template>
        <template v-else>
          <AsfInput class="hidden" type="hidden" :id="fieldID" :name="name" :value="options[0]?.value?.toString()" />
          <AsfInput :readonly="true" :label="label" :placeholder="options[0]?.label || options[0]?.value" />
        </template>
      </div>
      <div v-if="errorMessage" class="asf-select__error" v-e2e="`${fieldID}select-error`">
        <transition name="asf-fade">
          <span>{{ errorMessage }}</span>
        </transition>
      </div>
      <div v-if="caption" class="asf-select__caption" v-e2e="`${fieldID}select-help-text`">
        <slot name="content-caption-message" v-bind="{ caption }">
          <span>{{ caption }}</span>
        </slot>
      </div>
    </div>
  </div>
</template>
<style lang="postcss">
@import '@components/atoms/Select/Select.css';
</style>
